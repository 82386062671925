<template>
    <aside class="aside">
        <p class="title">{{ title ? title : "Інформація:" }}</p>
        <ul v-if="ankerList">
            <li
                v-for="(item, idx) in ankerList"
                :key="idx"
                :class="{ active: currentH2Idx === idx }"
                @click="currentH2Idx = idx"
            >
                <a :href="item.link" @click="onItemClick(item.title)">{{
                    item.title
                }}</a>
            </li>
        </ul>
    </aside>
</template>

<script>
export default {
    data() {
        return {
            currentH2Idx: 0,
        };
    },
    props: {
        ankerList: {
            type: [Array, Object],
        },
        title: {
            type: String,
        },
    },
    methods: {
        onItemClick(e) {
            this.$emit("click", e);
            setTimeout(() => {
                let el = document.getElementById(e);
                if (el) {
                    window.scrollTo({
                        top: el.offsetTop - 50,
                        behavior: "smooth",
                    });
                }
            }, 200);
        },
    },
};
</script>

<style lang="scss" scoped></style>
