<template>
  <div v-if="!isUiLocked" class="contentWrapper">
    <h2>{{ propsData.title }}</h2>
    <ul class="tabs">
      <li
        v-for="(item, idx) in propsData.list"
        :key="idx"
        @click="getIndex(idx)"
        :class="{ active: idx == currentIndex }"
      >
        {{ item.title }}
      </li>
    </ul>
    <div v-html="propsData.list[currentIndex].text"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    propsData: {
      type: [Array, Object],
    },
  },
  data() {
    return {
      currentIdx: 1,
    };
  },
  methods: {
    getIndex(idx) {
      this.currentIdx = idx;
    },
  },
  computed: {
    ...mapGetters(["isUiLocked"]),
    currentIndex() {
      return parseInt(this.currentIdx);
    },
  },
};
</script>

<style lang="sass" scoped>
.tabs
    display: flex
    border: 2px solid #4AA7BC
    box-sizing: border-box
    width: fit-content
    justify-content: center
    margin-top: 35px
    margin-bottom: 30px
    @media (max-width: 768px)
        width: 100%
        overflow-x: scroll
    li
        font-weight: 300
        font-size: 14px
        line-height: 100%
        color: #4AA7BC
        cursor: pointer
        white-space: nowrap
        margin-bottom: 0px
        padding: 12px 20px
        &:not(:last-of-type)
            border-right: 2px solid #4AA7BC
        &.active
            color: #17171D
            font-weight: 600
            background-color: #4AA7BC
</style>
