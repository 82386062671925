<template>
  <div>
    <flickity ref="mainSlider" class="mainSlider" :options="mainSettings">
      <div
        class="carousel-cell"
        v-for="(slide, idx) in galleryData.list"
        :key="idx"
        @click="openLightbox(idx)"
      >
        <img
          @load="loaded"
          :src="path(slide.image)"
          :alt="path(slide.image)"
        />
      </div>
    </flickity>
    <div class="navSliderWrapper">
      <flickity ref="navSlider" class="navSlider" :options="navSettings">
        <div
          class="carousel-cell"
          v-for="(item, idx) in galleryData.list"
          :key="idx"
          @click="getSlideId(idx)"
        >
          <div class="imgWarpper">
            <img
              @load="loaded"
              :src="path(item.image)"
              alt=""
            />
          </div>
        </div>
      </flickity>
      <button class="sliderBtn prevBtn" @click="previous()">
        <i class="ic-arrow-drop"></i>
      </button>
      <button class="sliderBtn nextBtn" @click="next()">
        <i class="ic-arrow-drop"></i>
      </button>
    </div>
    <app-carousell-lightbox
      v-if="isLightboxOpen && false"
      @close="closeLightbox"
    ></app-carousell-lightbox>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
import AppCarousellLightbox from "./app-carousell-lightbox.vue";
export default {
  components: {
    Flickity,
    AppCarousellLightbox,
  },
  props: {
      galleryData: {
          type: [Object, Array],
      },
  },
  data() {
    return {
      isLightboxOpen: false,
      currentNavSlide: 0,
      mainSettings: {
        prevNextButtons: false,
        pageDots: false,
      },
      navSettings: {
        prevNextButtons: false,
        contain: true,
        pageDots: false,
      },
    };
  },
  methods: {
    openLightbox() {
      this.isLightboxOpen = !this.isLightboxOpen;
    },
    closeLightbox() {
        this.isLightboxOpen = false;
    },
    getSlideId(id) {
        console.log(id - 1);
      this.$refs.navSlider.select(id - 1);
      this.$refs.mainSlider.select(id - 1);
    },
    loaded() {
      this.$refs.mainSlider.resize();
    },
    next() {
      this.$refs.navSlider.next();
      this.$refs.mainSlider.select(this.currentNavSlide);
    },
    previous() {
      this.$refs.navSlider.previous();
      this.$refs.mainSlider.select(this.currentNavSlide);
    },
  },
  mounted() {
    this.$refs.navSlider.on("change", (idx) => {
      this.currentNavSlide = idx;
    });
    this.$refs.mainSlider.on("change", (idx) => {
      this.$refs.navSlider.select(idx)
    });
  },
};
</script>

<style lang="sass">
.carousel-cell, .slide
    img
        transition: 0.3s ease
</style>
<style lang="sass" scoped>
.navSliderWrapper
    position: relative
    .sliderBtn
        position: absolute
        top: 50%
        transform: translateY(-50%)
        background-color: unset
        border: none
        i
            color: $c-btn
            font-size: 27px
    .prevBtn
        left: -55px
        transform: translateY(-50%) rotate(180deg)
        @media (max-width: 576px)
            left: -15px
    .nextBtn
        right: -55px
        @media (max-width: 576px)
            right: -15px
</style>
